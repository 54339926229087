<template>
<section class="hero is-large has-text-centered">
  <div class="hero-head stblue-background">
    <!-- <div class="box needs-padding is-border-green stblue-background"> -->
        <div class="field" >
          <label class="label has-text-white-bis calibri font-size-150 m-t-12">AA Instant Access</label>
        </div>
    <!-- </div> -->
  </div>
  
  <div class="column is-4 is-offset-4">
    <div class="box needs-padding is-border-green has-background-white">
      <div class="container">
        <img src="./../assets/aalogo.png" width="100%"/>
      </div>
      <div class="container">
        <div class="container">
          <div class="field" >
            <label class="label has-text-grey-light">Enter your membership number</label>
            <div class="control">
              <input class="input" type="text" v-model="code" placeholder="e.g. 12345678"/>
            </div>
          </div>
            <button class="button has-text-white-ter stblue-background is-rounded is-medium m-t-25 calibri font-size-200" @click="access"> CLICK HERE TO ACCESS </button>          
        </div>
        </div>
        <!-- <div class="field">
          <label class="label has-text-white-bis" v-text="decoded"/>
        </div> -->
    </div>
    <div class="container">
      <img src="./../assets/smarttile.svg" width="100%"/>
      <div class="field" >
        <label class="stblue-foreground calibri"> © Copyright 2020 CCTech Ltd | All Rights Reserved </label>
      </div>
    </div>
  </div>
  <div class="modal is-active" v-if="showModal">
      <div class="modal-background"></div>
      <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title has-text-grey-dark">Scan the Smart Tile</p>
            <button class="delete" aria-label="close" @click="closeModal"></button>
          </header>
          <section class="modal-card-body has-text-dark">
            <div class="content">
              <qrcode-stream @decode="onDecode"></qrcode-stream>
            </div>
          </section>
      </div>
    </div>
      <div class="modal is-active" v-if="showModalSuccess">
      <div class="modal-background"></div>
      <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title"></p>
            <button class="delete" aria-label="close" @click="closeModal"></button>
          </header>
          <section class="modal-card-body has-text-dark">
            <div class="content">
              <h1 class="title"> Scan successfull </h1>
            </div>
          </section>
      </div>
    </div>
</section>
</template>

<script>
import axios from 'axios';
export default {
  data(){
    return{
      code: '',
      showModal: false,
      showModalSuccess: false,
      decoded: '',
      passes: 0,
      correct: true,
      error: '',
      askcode: true
    };
  },
  beforeCreate: function(){
    if(document.referrer !== "https://aa.smarttile.co.uk/"){
      window.location.href = 'https://www.adrenalinealley.co.uk/';
    }
  },
  mounted: function(){
    this.code = JSON.parse(localStorage.getItem("code")) || '';
  },
  methods: {
    access(){
      this.showModal = true;
      localStorage.setItem("code", JSON.stringify(this.code));
    },
    closeModal(){
      this.showModal = false;
    },
    onDecode(decodedString){
      this.decoded = decodedString;
      const header = {
        headers: {token:'H54ergtt54!'} 
      }
      const body = {
        tag: this.decoded,
        code: this.code
      }
      axios.post('https://api-aac.smarttile.co.uk/api/aa/AccessWeb', body, header)
      .then((res)=> {
          if(res.status === 200){
              this.correct = true;
          }
      })
      .catch((error) => {
          console.log(error);
      });
      this.showModal = false;
      this.showModalSuccess = true;
      setTimeout(() => {
          this.showModalSuccess = false;
      }, 3000);
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.stblue-background {
  background-color: #11345a;
}
.stblue-foreground{
  color: #11345a;
}
.calibri {
  font-family: Calibri, 'Gill Sans', 'Gill Sans MT', 'Trebuchet MS', sans-serif;
  font-weight: normal;
}
.font-size-200{
  font-size: 200%;
}
.font-size-150{
  font-size: 150%;
}
.calibri-bold{
  font-weight: bold;
}
.m-t-25{
  margin: 25px;
}
.m-t-12{
  margin: 12px;
}
</style>
